import { graphql } from 'gatsby'
import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ContentFaqPage from '~/components/Content/Faq'

const Faq = ({ data }) => {
  const { faq, page } = data

  return page && faq ? (
    <Layout>
      <Metadata title={page?.name} />
      <ContentFaqPage faq={faq||{}} page={page||{}} />
    </Layout>
  ) : null
}

export default Faq

export const query = graphql`
  query FaqPage($locale: String) {
    page: contentfulPage(slug: { eq: "faq" }, node_locale: { eq: $locale }) {
      name
      ...ContentSectionFragment
    }
    faq: contentfulFaq(slug: { eq: "faq" }, node_locale: { eq: $locale }) {
      ...ContentFaqFragment
    }
  }
`
