/** @jsx jsx */
import PropTypes from 'prop-types'
import { Box, Flex, jsx, useColorMode } from 'theme-ui'
import ContentFaqTopic from '~/components/Content/Faq/Topic'
import TableOfContents from '~/components/Content/Faq/TableOfContents'
import useMatchMedia from '~/hooks/utils/use-match-media'
import Selector from '~/components/Content/Faq/ScrollSelector'
import Header from '~/components/Content/PageHeader/header'
import { useRef, useState, useEffect } from 'react'

const ContentFaq = ({ faq, page }) => {
  const media = useMatchMedia('(max-width:768px)')
  const [activeTopic, setActiveTopic] = useState(faq.topics[0].id)
  const ref = useRef()
  const [, setColorMode] = useColorMode()
  //run on mount
  useEffect(() => {
    setColorMode('FAQ')
  }, [])
  return (
    <Box>
      <Header section={page.sections[0]} />
      <Box
        margin={0}
        sx={{
          padding: ['0 32px', '0 60px'],
          pb: ['90px', '120px'],
          width: '100vw',
          backgroundColor: 'frenchPass',
          color: 'governorBay',
          height: '100%',
          isolation: 'isolate'
        }}
      >
        {media && <Selector topics={faq.topics} />}
        {!media && (
          <TableOfContents
            forwardRef={ref}
            dataTopics={faq.topics}
            dataActive={activeTopic}
          />
        )}
        {faq.topics.map((topic, i) => {
          return (
            <Flex
              key={topic.id + i}
              sx={{
                gap: '60px',
                ml: [0, '200px'],
                mt: [
                  null,
                  ref.current && i === 0
                    ? `calc(${ref.current.clientHeight * -1 + 'px'} - 4.5rem)`
                    : 0
                ]
              }}
              id={topic.id}
            >
              <ContentFaqTopic
                topic={topic}
                id={i}
                setActiveTopic={setActiveTopic}
              />
            </Flex>
          )
        })}
      </Box>
    </Box>
  )
}

export default ContentFaq

ContentFaq.propTypes = {
  faq: PropTypes.shape({
    topics: PropTypes.array
  }),
  page: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        media: PropTypes.arrayOf(PropTypes.shape({})),
        colorTheme: PropTypes.shape({
          slug: PropTypes.string
        })
      })
    )
  })
}
