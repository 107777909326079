/* eslint-disable jsx-a11y/no-onchange */
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

const Selector = ({ topics }) => {
  const [select, setSelect] = useState('')

  const handleChange = e => {
    setSelect(e.target.value)
  }

  useUpdateEffect(() => {
    window.location.hash = select
  }, [select])

  return (
    <div
      sx={{
        pb: '30px',
        '&:after': {
          content: "''",
          position: 'absolute',
          backgroundImage: 'url(/images/arrow_blue.svg)',
          backgroundRepeat: 'no-repeat',
          transform: 'translate(-32px, 24px)',
          height: '10px',
          width: '10px',
          pointerEvents: 'none'
        }
      }}
    >
      <select
        sx={{
          height: '50px',
          backgroundColor: '#FFFFFF',
          color: 'governorBay',
          fontWeight: 'heading',
          fontSize: '16px',
          lineHeight: '20px',
          fontFamily: 'body',
          pt: '12px',
          pb: '12px',
          px: '20px',
          pr: '40px',
          border: '2px solid #2B38BD',
          borderRadius: '8px',
          appearance: 'none',
          width: '95%',

          'select::-ms-expand': {
            display: 'none'
          }
        }}
        value={select}
        onChange={handleChange}
      >
        <option value="">Jump to</option>
        {topics.map(topic => {
          return (
            <option key={topic.id} value={topic.id}>
              {topic.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default Selector
